import React from "react"
import { graphql } from 'gatsby';
import { Link } from "gatsby";
import loadable from '@loadable/component'
import  'bootstrap/dist/css/bootstrap.min.css';
const Footer = loadable(() => import('../components/Footer'))
const Nav = loadable(() => import ('../components/Nav'))
// const TopNav = loadable(() => import ('../components/TopNav'))

function NewsDetail({pageContext}){
    console.log(pageContext.body, "page")
     return(
        <>
        {/* <TopNav/> */}
      <Nav/>
<section className=" bg-light-gray padding-35px-tb page-title-small top-space-verum">
<div className="container">
    <div className="row align-items-center">
        <div className="col-xl-6 col-md-6 d-flex flex-column justify-content-center">
            <h1 className="alt-font text-extra-dark-gray font-weight-600 no-margin-bottom">{pageContext.title}</h1>
        </div>
    </div>
</div>
</section>
<section className="padding-70px-tb  hover-option4 blog-post-style3">
<div className="container slide-right">
    <div className="row">
        <main className="col-12 col-lg-9 right-sidebar md-margin-60px-bottom sm-margin-40px-bottom pl-0 md-no-padding-right">
            <div className="row">
                <div className="col-12">
                    <p>
                        {pageContext.video ? 
<video autoplay="" muted loop="" controls playsinline="" className="pofo-html5-video">
<source type="video/mp4" src={pageContext.video}/></video>   
                            :
                            <img width="1200"height="628" src={pageContext.featuredImage} alt=""/>
                        }  
                        
                    </p>

                    <div
      dangerouslySetInnerHTML={{__html: pageContext.body}}
    />
                </div>
            </div>
        </main>
        <aside className="col-12 col-lg-3 float-right">
            <div className="d-inline-block width-100 margin-25px-bottom">
                <form>
                    <div className="position-relative">
                        <input type="email" className="bg-transparent text-small m-0 border-color-extra-light-gray medium-input float-left" placeholder="Enter your keywords..."/>
                        <button className="bg-transparent  btn position-absolute right-0 top-1"><i className="fas fa-search no-margin-left"></i></button>
                    </div>   
                </form>
            </div>
            <div className="bg-light-gray">
                <div className="margin-20px-bottom font-weight-600 aside-title"><span>Archive</span></div>
                <ul className="list-style-6 margin-20px-bottom ">
                    <Link to="/News/2022"> <li><a href="blog-grid.html">2022</a><span>5</span></li></Link>
                    <Link to="/News/2021"><li><a href="blog-grid.html">2021</a><span>11</span></li></Link>
                    <Link to="/News/2020"> <li><a href="blog-grid.html">2020</a><span>11</span></li></Link>
                </ul>   
            </div>
        </aside>
    </div>
</div>
</section>
<Footer/>
</>)
 }
export default NewsDetail

graphql`
query NewsQuery {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(/News/)/"}}) {
        edges {
          node {
            frontmatter {
              date
              thumbnail
              featuredImage
              title
              video
              
            }
            id
            rawMarkdownBody
          }
        }
      }
}
`

